<!-- 사용안함 -->
<template>
  <div class="box_sorting">
    <strong class="screen_out">프로젝트 검색하기</strong>
    <ul class="list_sorting">
      <li class="item_full">
        <em class="tit_txt">등록일</em>
        <div class="cont_sorting">
          <div class="row_pack">
            <div class="box_picker">
              <flat-pickr
                v-model="searchForm.draftFromDate"
                class="txt_time"
                :config="flatpickrConfigDayDraftFrom"
                placeholder="날짜선택"
                name="date"
                @on-change="listenToOnChangeDayDraftFrom"
                @on-close="listenToOnCloseDay"
              />
              <span class="ico_account ico_calendar" />
              <span class="txt_bar">~</span>
              <flat-pickr
                v-model="searchForm.draftToDate"
                class="txt_time"
                :config="flatpickrConfigDayDraftTo"
                placeholder="날짜선택"
                name="date"
                @on-change="listenToOnChangeDay"
                @on-close="listenToOnCloseDay"
              />
              <span class="ico_account ico_calendar" />
            </div>
            <button class="btn_small btn_fourthly" @click="onChangeDatesRadio('ONE_WEEK')">
              1주일
            </button>
            <button class="btn_small btn_fourthly" @click="onChangeDatesRadio('ONE_MONTH')">
              1개월
            </button>
            <button class="btn_small btn_fourthly" @click="onChangeDatesRadio('TWO_MONTH')">
              2개월
            </button>
            <button class="btn_small btn_fourthly" @click="onChangeDatesRadio('SIX_MONTH')">
              6개월
            </button>
          </div>
        </div>
      </li>
      <li class="item_full">
        <em class="tit_txt">검색어</em>
        <!-- {{titleSearch}} -->
        <div class="cont_sorting">
          <div class="group_search">
            <comm-opt
              class="w208"
              :classList="['type_short']"
              :optionDesc="'검색 선택상자'"
              :optionName="searchForm.searchType.name"
              :optionList="SEARCH_TYPE_SELECTBOX_LIST"
              :eventName="'select'"
              @select="selectSearchType"
            />
            <div class="group_input">
              <Input :value.sync="searchForm.searchKeyword" @onKeyUpEnter="keypress" />
            </div>
          </div>
        </div>
      </li>
    </ul>
    <!-- // list_sorting -->
    <div class="wrap_btn">
      <button type="button" class="btn_medium btn_primary" @click.prevent="clickSearchButton">
        검색
      </button>
    </div>
  </div>
</template>

<script>
// import axios from 'axios';
import CommOpt from "@/_approval/components/common/CommOpt";
import Input from "@/_approval/components/common/input/Input";
import DatePicker from "@/components/common/calendar/DatePicker";
import DateRangeQuickSelect from "@/components/common/calendar/DateRangeQuickSelect";

import { default as CommFlatpickrMixin } from "@/_approval/mixins/common/commFlatpickr.js";
import { dateToStringWithDiv, getDateRange } from "@/utils/dateUtils";

export default {
  name: "PopPurchaseContractFilter",
  components: {
    CommOpt,
    Input,
    DatePicker,
    DateRangeQuickSelect,
  },
  mixins: [CommFlatpickrMixin],
  props: {
    searchForm: Object,
  },
  data() {
    return {
      // searchForm:{
      //   draftFromDate: '',
      //   draftToDate: '',
      //   searchType: { code: 'title', name: '구매요청명' },
      //   searchKeyword:''
      // },
      flatpickrConfigDayDraftFrom: {},
      flatpickrConfigDayDraftTo: {},
      flatpickrConfigDayCompleteFrom: {},
      flatpickrConfigDayCompleteTo: {},
      SEARCH_TYPE_SELECTBOX_LIST: [
        { code: "contractCid", name: "계약번호" },
        { code: "title", name: "계약명" },
        { code: "requester", name: "구매담당자" },
      ],
    };
  },
  // create(){
  //   this.flatpickrConfigDayDraftFrom = { ...this.flatpickrConfigDay };
  //   this.flatpickrConfigDayDraftTo = { ...this.flatpickrConfigDay };
  //   this.flatpickrConfigDayCompleteFrom = { ...this.flatpickrConfigDay };
  //   this.flatpickrConfigDayCompleteTo = { ...this.flatpickrConfigDay };
  // },
  methods: {
    listenToOnChangeDay(dObj, dStr, fp, dayElem) {
      // dObj는 range date의 시작일, 종료일이 배열로 저장되어 있음
    },
    listenToOnCloseDay(dObj, dStr, fp, dayElem) {},
    listenToOnChangeDayDraftFrom(dObj, dStr, fp, dayElem) {
      // dObj는 range date의 시작일, 종료일이 배열로 저장되어 있음
      this.flatpickrConfigDayDraftTo.minDate = dStr;
      this.flatpickrConfigDayDraftTo = { ...this.flatpickrConfigDayDraftTo };

      // this.calculateDateEnd = null;
    },
    listenToOnChangeDayCompleteFrom(dObj, dStr, fp, dayElem) {
      // dObj는 range date의 시작일, 종료일이 배열로 저장되어 있음
      this.flatpickrConfigDayCompleteTo.minDate = dStr;
      this.flatpickrConfigDayCompleteTo = {
        ...this.flatpickrConfigDayCompleteTo,
      };
    },
    selectSearchType(searchType) {
      this.searchForm.searchType = searchType;
    },
    onChangeDatesRadio(date) {
      const dateStandard = getDateRange(date);
      const today = new Date();

      if (dateStandard) {
        this.searchForm.draftFromDate = dateToStringWithDiv(dateStandard);
        this.searchForm.draftToDate = dateToStringWithDiv(today);
      }
    },
    clickSearchButton() {
      this.$emit("select-connector", this.searchForm); // 부모 함수(selectDocList)에게 넘김
    },
    keypress(key) {
      // if (key.which === 13) {
      //   this.$emit('select-connector', this.searchForm);
      // }
    },
  },
};
</script>
